
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/icon/icon24.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'
import img5 from '../images/svg/icon-5.svg'
import img6 from '../images/svg/icon-6.svg'
import img7 from '../images/svg/icon-7.svg'
import img8 from '../images/svg/icon-8.svg'

const dataPortfolio2 = [

    {
        id: 1,
        img: img1,
        title: 'Project Analysis',
        desc: 'Understand the issues the SAP implementation is expected to resolve.'
    },
    {
        id: 2,
        img: img8,
        title: 'Implementation Strategizing',
        desc: 'Devise a strategy to execute SAP implementation seamlessly.'
    },
    {
        id: 3,
        img: img7,
        title: 'SAP Implementation',
        desc: 'Implement SAP modules and required solutions seamlessly into your business model.'
    },
    {
        id: 4,
        img: img6,
        title: 'Performance Measurement',
        desc: 'Test the implemented modules for performance'
    },

    {
        id: 5,
        img: img5,
        title: 'Final Optimizations',
        desc: 'Calibrate the SAP modules for maximum efficiency before product handover.'
    },

    




]

export default dataPortfolio2;