import React, { useState } from "react";
import Button from "../button/Button";
import img2 from "../../assets/images/layouts/hana-img-2.png";

function About3(props) {
  const [dataBlock] = useState({
    subheading: "About",
    heading: "Your SAP S/4 HANA Experts",
    desc1:
      "As a leading provider of SAP solutions, we leverage the power of the next-generation ERP business suite to drive efficiency, innovation, and growth for businesses across industries.",
    desc2: "Explore how our expertise can elevate your organization.",
  });
  const [dataCard] = useState([
    {
      id: 1,
      img: "https://trigeminal.blr1.digitaloceanspaces.com/object-upload-test/hana-img-1.png",
    },
    {
      id: 2,
      img: img2,
      class: "sm",
    },
  ]);
  return (
    <section className="about s3">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="block-text pd-0">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="mb-17">{dataBlock.desc1}</p>
              <p className="mb-26">{dataBlock.desc2}</p>
              <Button title="View More" link="/upgradation" />
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div
              className="about__right"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              {dataCard.map((idx) => (
                <div key={idx.id} className={`card-box ${idx.class}`}>
                  <div className="top d-flex">
                  
                    <div>
                      <h6>{idx.title}</h6>
                      <h6 className="price">{idx.price}</h6>
                    </div>
                  </div>
                  <div className="content">
                    <div className="image">
                      <img src={idx.img} alt="Cyfoniisss" />
                    </div>

                    {/* <div className="info d-flex">
                                                <img src={idx.avt} alt="Trigeminal" />

                                                <div >
                                                    <h6 className="name">{idx.name}</h6>
                                                    <p>{idx.tag}</p>
                                                </div>
                                            </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About3;
