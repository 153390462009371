import React , {useState} from 'react';

import Button from '../button/Button';



function Token1(props) {

    
    return (
        <section className="token">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    
                    <div className="col-xl-12 col-md-12">
                        <div className="token__main" data-aos="fade-left" data-aos-duration="2000">
                        <h4 className="heading">Benefits of SAP ERP Services</h4>
                          
                            <ul style={{marginTop:'50px',}} className="token-list">
                                <li>
                                    <div  className="name ">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Scalability to accommodate business growth.</p></div>
                                </li>
                                <li>
                                    <div className="name">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Instant adaptability for better supply chain control.</p></div>
                                    
                                </li>
                                <li>
                                    <div className="name">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Embedded AI for predictive insights and process automation.</p></div>
                                </li>
                                <li>
                                    <div className="name">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Real time data analysis and reporting.</p></div>
                                </li>
                                <li>
                                    <div className="name">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>

                                    <p>Access to Big Data and IoT capabilities.</p></div>
                                </li>

                                <li>
                                    <div className="name">
                                    <svg width="12" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>
                                    <p>Platform optimization for seamless transition to the cloud environment</p></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default Token1;