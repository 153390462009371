import React  from 'react';
import Faqs from '../components/faqs/Faqs';
import dataFaqs from '../assets/fake-data/data-faq';
import Footer2 from '../components/footer/Footer2';
import Speciality6 from '../components/speciality/Speciality6';
import dataBox6 from '../assets/fake-data/data-box-6';
import PageTitle10 from '../components/pagetitle/PageTitle10';

function Products(props) {

    return (
       <>
        <div className='page-roadmap wrapper'>


         

<PageTitle10 title='Products' />

<Speciality6 data={dataBox6} />





<Faqs data={dataFaqs} />

<Footer2 />


</div>


       </>
    );
}

export default Products;