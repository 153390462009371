import React , {useState} from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
import Counter from '../components/counter/Counter';
import img2 from '../assets/images/layouts/about-02.png'
import img3 from '../assets/images/layouts/about-03.png'
import img4 from '../assets/images/layouts/about-04.png'
import dataRoadMap1 from '../assets/fake-data/data-roadmap1';
import Roadmap1 from '../components/roadmap/Roadmap1';
import Button from '../components/button/Button';
import img from '../assets/images/layouts/create.png'
import Footer2 from '../components/footer/Footer2';




function VisionsMission(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : "https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/about-01.png",
            class: 'img1'
        },
        {
            id: 2,
            img : img2,
            class: 'img2'
        },
        {
            id: 3,
            img : img3,
            class: 'img3'
        },
        {
            id: 4,
            img : img4,
            class: 'img4'
        },
        {
            id: 5,
            img : "https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/about-05.png",
            class: 'img5'
        },
    ])
    return (
        <div className='page-visions wrapper'>

            <PageTitle title='SAP Services' />
           
            <section className="about">
                <div className="container">
                    <div className="row">
                        
                        <div  className="col-xl-6 col-md-12">
                            <div className="block-text">
                                <h6 className="sub-heading"><span>Services</span></h6>
                                <h3 className="heading wow" data-splitting>Our SAP Services</h3>
                                    <p className="mb-17">At Trigeminal.ai, we understand that your business transformation journey begins with a vision. We are here to empower your vision with our comprehensive SAP services and solutions. Explore how we can boost your business transformation:</p>
                            </div>
                            <div className="block-text">
                                <h6 className="sub-heading"><span>Why SAP</span></h6>
                                <h3 className="heading wow" data-splitting>Consulting Support</h3>
                                    <p>A sophisticated application like SAP requires vital post-implementation support for businesses to maximize benefits. At Trigeminal.ai, we prioritize addressing client obstacles and offer uninterrupted support for SAP, including monitoring, patch upgrades, and landscape optimization.</p>
                            </div>
                        </div>

                        
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <Roadmap1  data={dataRoadMap1} />
                    </div>
                </div>
            </section>

            
            <Counter/>  

         

            {/* <Faqs data={dataFaqs} /> */}

           
            <section  className="create">
                <div className="container">
                    <div  className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">Let's Talk:</h4>
                                    <p>Ready to kickstart your business transformation <br/>
                                         journey with SAP? Fill out the form below for a free consultation, <br /> and we'll get in touch with you promptly.</p>
                                    <Button title='Contact' link='/contact' />
                                
                                </div>
                                <img  src={img} alt="Cyfonii" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer2 />
        </div>
    );
}

export default VisionsMission;