import React, { useState } from "react";

import Button from "../button/Button";

function Token3(props) {
  const [dataBlock] = useState({
    subheading: "Information",
    heading: "Your Trusted Marketing Partner: TriADS",
    desc: "Ultimate solution for your premier branding, Social Media Management, Web App, Digital Marketing, and Website Development Partner!",
    desc1:"With a focus on innovation and a commitment to excellence, we are dedicated to driving your business success through cutting-edge strategies and personalized solutions tailored to your unique goals."
  });
  return (
    <section className="token">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          {/* <div className="col-xl-6 col-md-12">
            <div className="block-text pd-0">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="mb-17">{dataBlock.desc}</p>
              <p className="mb-17">{dataBlock.desc1}</p>
              
            </div>
          </div> */}
          <div className="col-xl-12 col-md-12">
            <div className="content">
              <video
                src="https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/TriADS/TriADS%20Logo.mp4"
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Token3;
