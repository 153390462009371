import React , {useState} from 'react';

import Button from '../button/Button';
import Partner from '../partner/Partner';


function About4(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About',
            heading: 'SAP Upgradation Services',

            desc1: "Stay ahead of the curve with Trigeminal.ai's comprehensive SAP upgradation services. ",
            desc2: 'As technology evolves rapidly, keeping your SAP solutions up-to-date is crucial for maintaining competitiveness and driving innovation. Discover how our expertise can help align your SAP platform with the latest trends and advancements.',
            desc3:"Upgrade your SAP solutions with ease and confidence with Trigeminal.ai's specialized upgradation services. Our team of experienced SAP developers and consultants works tirelessly to ensure your business remains at the forefront of technological innovation."
        }
    )
    return (
        <section className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-text center pd-0">
                                    <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                    <h3 className="heading pd">{dataBlock.heading}</h3>
                                        
                                </div>

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src="https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/sap-upgradation.png" alt="Trigeminal" />
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    <h6 className="sub-heading mb-17"><span>How SAP Upgradation Helps:</span></h6>
                                    <p className="mb-30">{dataBlock.desc3}</p>
                                    <Button title='More About Us' link='/consulting' />
                                </div>

                                <Partner />
                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About4;