
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/icon/icon24.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-6.svg'
import img5 from '../images/svg/icon-5.svg'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Requirement Analysis',
        desc: 'Understand your business goals and requirements to tailor our solutions accordingly.'
    },
    {
        id: 2,
        img: img3,
        title: 'Strategy Creation',
        desc: 'Design a unique strategy for migrating your data to SAP S/4 HANA, ensuring a seamless transition.'
    },
    {
        id: 3,
        img: img2,
        title: 'Execution',
        desc: 'Implement SAP modules and solutions seamlessly into your business model, integrating innovative features and functionalities.'
    },
    {
        id: 4,
        img: img4,
        title: 'Performance Measurement',
        desc: 'Test the implemented solution for performance and efficiency, ensuring optimal results.'
    },

    {
        id: 5,
        img: img5,
        title: 'Final Optimizations',
        desc: 'Fine-tune the SAP S/4 HANA solution for maximum efficiency before product handover, ensuring a smooth transition.'
    },



]

export default dataPortfolio;