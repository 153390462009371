import React from 'react';

import PageTitle from '../components/pagetitle/PageTitle';
import Footer from '../components/footer/Footer';
import BlogList1 from '../components/blog/BlogList1';
import dataBlog from '../assets/fake-data/data-blog';
import PageTitle5 from '../components/pagetitle/PageTitle5';
import Footer2 from '../components/footer/Footer2';




function Blog(props) {
    return (
        <div className='wrapper'>

            <PageTitle5 title='Blogs' />

            <BlogList1 data={dataBlog} />

            <Footer2 />
            
        </div>
    );
}

export default Blog;