import img1 from '../images/icon/icon01.png'
import img2 from '../images/icon/icon02.png'
import img3 from '../images/icon/icon03.png'
import img4 from '../images/icon/icon04.png'
import img5 from '../images/icon/icon05.png'
import img6 from '../images/icon/icon06.png'

const dataBox6 = [

    {
        id: 1,
        img: img1,
        title: 'TriCytoAI',
        desc: "Harness the precision of AI with our advanced cytology solution, revolutionizing early detection and optimizing laboratory workflows for unparalleled patient care."
    },
    {
        id: 2,
        img: img2,
        title: 'TriLIMS',
        desc: 'Elevate lab operations with our Laboratory Information Management System, seamlessly integrating data management and workflow optimization for peak efficiency and accuracy.'
    },

    {
        id: 3,
        img: img3,
        title: 'TriFormScan',
        desc: 'Optimize and centralize your content with our Digital Asset Management (DAM) system, ensuring streamlined access and effortless organization of all your digital assets.'
    },
    {
        id: 4,
        img: img4,
        title: 'TriPathViewer',
        desc: "Experience enhanced visualization with our pathology slide viewer, coupled with an intuitive sharing tool for seamless collaboration and accurate diagnoses."
    },
    {
        id: 5,
        img: img5,
        title: 'TriForecast',
        desc: "Navigate your financial future with precision using our financial forecasting tool, designed to provide insightful projections and empower data-driven decisions."
    },
    {
        id: 6,
        img: img6,
        title: 'TriPhotoShare',
        desc: 'Discover smart photo organization with our AI-powered management system, ensuring intuitive categorization and effortless sharing for all your cherished moments.'
    },
    

]

export default dataBox6;