

const dataRoadMap = [

    {
        id: 1,
        time: 'Digital Footprint',
        title: 'Minimize Digital Footprint',
        desc: 'Streamline and reduce the digital footprint to enhance efficiency and security.',
        class: ''
    },
    {
        id: 2,
        time: 'Supply Chain ',
        title: 'Enhanced Supply Chain Management',
        desc: 'Gain better control and visibility over the supply chain processes for improved efficiency and cost-effectiveness.',
        class: 'right'
    },

    {
        id: 3,
        time: 'AI',
        title: 'AI Integration and Automation',
        desc: 'Embed advanced AI and automation features to streamline processes and enhance decision-making capabilities.',
        class: ''
    },

    {
        id: 4,
        time: 'Data Insights',
        title: 'Real-time Data Insights',
        desc: 'Leverage real time data analysis and reporting to make informed decisions promptly and accurately.',
        class: 'right'
    },
    {
        id: 5,
        time: 'IoT',
        title: 'Utilize Big Data and IoT',
        desc: 'Access and leverage vast volumes of data from Big Data sources and IoT devices to drive insights and innovation.',
        class: ''
    },

    {
        id: 5,
        time: 'Workflow',
        title: 'Optimized Workflow and Customer Experience',
        desc: 'Upgrade workflows and enhance customer experiences through seamless integration and optimized processes.',
        class: 'right'
    },



]

export default dataRoadMap;