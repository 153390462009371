import React from 'react';
import Roadmap2 from '../components/roadmap/Roadmap2';
import Banner3 from '../components/banner/Banner3';
import About3 from '../components/about/About3';
import Token from '../components/token/Token';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import Speciality from '../components/speciality/Speciality';
import dataBox from '../assets/fake-data/data-box';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import Portfolio from '../components/portfolio/Portfolio';
import Footer2 from '../components/footer/Footer2';


function Home03(props) {
    return (
     <>
        <div className='page-about wrapper'>
          
          <Banner3 />
  
          <About3 />
  
          <Token />
  
          <Roadmap2  data={dataRoadMap} />
  
          <Speciality data={dataBox} />
  
  
          <Portfolio data={dataPortfolio} />
  
          {/* <Project data={dataProject} />
  
        
  
  
          <Team2 data={dataTeam} />
  
          
  
          <Testimonials data={dataTestimonials} />
  
          <Counter />  
  
          <Blog data={dataBlog} />
  
          <Faq3 data={dataFaqs} /> */}
  
  
          
  
          </div>
  
  <Footer2  />
     </>
    );
}

export default Home03;