import img1 from '../images/layouts/banner-ai.jpg'
import img2 from '../images/layouts/banner-app.jpg'
import img3 from '../images/layouts/banner-design.jpg'
import img4 from '../images/layouts/banner-market.jpg'
import img5 from '../images/layouts/banner-graphics.jpg'
import img6 from '../images/layouts/banner-branding.jpg'
import img7 from '../images/layouts/banner-data.jpg'
import img8 from '../images/layouts/banner-social.jpg'



const dataItem = [

    {
        id: 1,
        img: img1,
        title: 'AI Softwares',
    },
    {
        id: 2,
        img: img2,
        title: 'Web Applications',
    },
    {
        id: 3,
        img: img3,
        title: 'Web Designing',
    },
  
    {
        id: 4,
        img: img4,
        title: 'Digital Marketing',
    },
    {
        id: 5,
        img: img5,
        title: 'Graphic Designing',
    },
    
    {
        id: 6,
        img: img6,
        title: 'Branding & Logo',
    },
    {
        id: 7,
        img: img7,
        title: 'Data Analytics & Forecasting',
    },

    {
        id: 8,
        img: img8,
        title: 'Social Media Management',
    }
    

]

export default dataItem;