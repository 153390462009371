import React , {useState} from 'react';

import Button from '../button/Button';



function Token(props) {

    const [dataBlock] = useState(
        {
            subheading: 'SAP S/4 HANA Services',
            heading: 'Customized SAP S/4 HANA Services',
            desc: 'Unlock the full potential of SAP S/4 HANA with our tailored services designed to address your unique business needs:' ,
            
        }
    )
    return (
        <section className="token">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text pd-0">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading">{dataBlock.heading}</h3>
                                <p className="mb-17">{dataBlock.desc}</p>
                                <Button title='Learn More' link='/implementation' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12" >
                        
                        <div className="token__main" data-aos="fade-left" data-aos-duration="2000">
                            <h5 style={{marginBottom:"20px"}}>Features</h5>
                          
                            <ul className="token-list">
                                <li>
                                    <div className="name">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="white" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z"/></svg>
                                    <p>Basic settings for creating the system landscape</p></div>
                                </li>
                                <li>
                                    <div className="name">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="white" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z"/></svg>

                                    <p>Basic settings to prepare the transfer of data from SAP S/4HANA to IBP</p></div>
                                </li>
                                <li>
                                    <div className="name">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="white" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z"/></svg>
                                    <p>Application-specific settings and enhancements</p></div>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}

export default Token;