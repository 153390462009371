
import img1 from '../images/icon/icon11.svg'
import img2 from '../images/icon/icon2.svg'
import img3 from '../images/icon/icon12.svg'
import img4 from '../images/icon/icon13.svg'


const dataBox4 = [

    {
        id: 1,
        img: img1,
        title: ' Migration',
        desc: "Seamlessly migrate your existing systems to SAP with our SAP-certified developers. We ensure a unified and risk-free transition while preserving the integrity of your crucial business data."
    },
    {
        id: 2,
        img: img2,
        title: 'Implementation',
        desc: 'As a certified SAP HANA consulting company, we craft tailored implementation plans that offer a comprehensive overview of your business and address critical requirements. Our customer-centric approach ensures the deployment of bespoke SAP solutions aligned with your unique business needs.'
    },
    {
        id: 3,
        img: img3,
        title: 'Upgradation',
        desc: 'Stay ahead of the curve by upgrading your existing SAP solutions with our remarkable consulting services. Our experts work closely with your SAP team to ensure a smooth transition to the latest SAP technologies.'
    }, 
    {
        id: 3,
        img: img4,
        title: 'Support Services',
        desc: 'Entrust the management of your SAP system to our experts. We provide comprehensive support, including bug fixes, timely maintenance, and update releases, ensuring uninterrupted operations and optimal performance.'

    }
    


]

export default dataBox4;