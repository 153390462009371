import React from 'react';
import dataBox2 from '../assets/fake-data/data-box2';
import PageTitle1 from '../components/pagetitle/PageTitle1';
import About4 from '../components/about/About4';
import Speciality3 from '../components/speciality/Speciality3';
import dataPortfolio3 from '../assets/fake-data/data-portfolio3';
import Portfolio3 from '../components/portfolio/Portfolio3';
import Footer2 from '../components/footer/Footer2';



function SapUpgradation(props) {
    return (
        <div className='page-about wrapper'>

            <PageTitle1 title="SAP Upgradation Services" />

            <About4 />


            <Speciality3 data={dataBox2} />
           

            {/* <Counter />   */}

            <Portfolio3 data={dataPortfolio3} />

            {/* <Team data={dataTeam} /> */}

            {/* <section className="create">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">Let's Talk:</h4>
                                    <p>Ready to kickstart your business transformation <br/>
                                         journey with SAP? Fill in the form below for a free consultation, <br /> and we'll get in touch with you promptly.</p>
                                    <Button title='Contact' link='/contact' />
                                
                                </div>
                                <img src={img} alt="Trigeminal" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer2 />
            
        </div>
    );
}

export default SapUpgradation;