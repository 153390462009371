
import img1 from '../images/icon/icon20.svg'
import img2 from '../images/svg/icon-1.svg'
import img3 from '../images/icon/icon22.svg'
import img4 from '../images/icon/icon23.svg'



const dataPortfolio5 = [

    {
        id: 1,
        img: img1,
        title: 'Performance Monitoring',
        desc: "Our skilled SAP developers assess and analyze the performance and throughput of your SAP solution, providing exceptional performance monitoring solutions."
    },
    {
        id: 2,
        img: img2,
        title: 'SAP Updates',
        desc: 'Stay ahead of the curve with the latest trends and advancements in SAP technology. We ensure your infrastructure is equipped to handle the latest updates seamlessly.'
    },
    {
        id: 3,
        img: img3,
        title: 'Maintenance & Backup',
        desc: 'We help organizations create comprehensive backups of SAP applications and data while providing regulated maintenance to ensure smooth operations.'
    },
    {
        id: 4,
        img: img4,
        title: 'Documentation & Reporting',
        desc: 'Our team exercises caution in rendering reports and assessments to our clients, providing timely insights and recommendations as needed.'
    }

 

    




]

export default dataPortfolio5;