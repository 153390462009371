import React , {useState} from 'react';



function Speciality(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subheading: 'SAP Implementation',
            heading: 'Explore Our SAP Implementation Services',
            // desc: "SAP provides industry-specific modules that can be seamlessly integrated into any business model, regardless of size or location. By implementing SAP, you can simplify departmental management, enhance productivity, and improve communication within your organization. Trigeminal.ai specializes in SAP consulting, support, and implementation services, trusted by some of the world's most prestigious companies." ,
            
        }
    )
    return (
        <section className="speciality">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading pd">{dataBlock.heading}</h3>
                                    <p className="">{dataBlock.desc}</p>
                            </div>
                        </div>
                        {
                            data.map(idx => (
                                <div key={idx.id} className="col-xl-4 col-md-4 col-sm-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img style={{color:"white"}} src={idx.img} alt="Trigeminal" />
                                        </div>
                                        <h5 className="title">{idx.title}</h5>
                                        <p>{idx.desc}</p>
                                        <h3 className="number">0{idx.id}</h3>
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </section>

    );
}

export default Speciality;