import React , {useState} from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { title } from 'faker/lib/locales/az';

Faq4.propTypes = {
    data: PropTypes.array
};

function Faq4(props) {
    const {data} =props;

    const [dataBlock] = useState(
        {
            heading: 'SAP Services & Solutions for Different Industries',
            desc:'Drawing on our deep domain knowledge, we offer customizable SAP solutions tailored to the unique requirements of various industries. From education and healthcare to entertainment and real estate, our experts efficiently map your needs and deliver competitive advantages through cutting-edge SAP solutions.'
            
        }
    )

    const [dataTab] = useState([
        {
            id: 1,
            title: 'Education',
        },
        {
            id: 2,
            title: 'Healthcare',
        },
        {
            id: 3,
            title: 'Entertainment',
        },
        {
            id: 4,
            title: 'Business',
        },
        {
            id: 5,
            title: 'Human Resource'
        },
        {
            id:6,
            title:'Travel'
        },
        {
            id:7,
            title:'Transport'
        },
        {
            id:8,
            title:'Food'
        },
        {
            id:9,
            title:'Real Estate'
        },
        {
            id:10,
            title:'Sports'
        }

    ]);

    const [useTab1] = useState([
        {
            id:1,
        title:'Scalability to accommodate business growth'
        },
        {
            id:2,
            title:'Instant adaptability for better supply chain control'
        }
    ])
    return (
        <section className="faq s3">
        <div className="container">
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="block-text center" >
                        <h3 className="heading pd">{dataBlock.heading}</h3>
                        <p >{dataBlock.desc}</p>
                            
                    </div>
                    <div className="faq__main flat-tabs">

                        <Tabs>
                            <TabList>
                                {
                                    dataTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))
                                }
                                
                            </TabList>

                              
 
                            </Tabs> 

                      </div>                    
                </div>

               

            </div>
        </div>
    </section>
    );
}

export default Faq4;