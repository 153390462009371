import React from 'react';
import dataTeam from '../assets/fake-data/dataTeam';
import Faq3 from '../components/faqs/Faq3';
import dataFaqs from '../assets/fake-data/data-faq';
import Counter from '../components/counter/Counter';
import Blog from '../components/blog/Blog';
import dataBlog from '../assets/fake-data/data-blog';
import Footer2 from '../components/footer/Footer2';
import Banner4 from '../components/banner/Banner4';
import About8 from '../components/about/About8';
import Token3 from '../components/token/Token3';
import dataProject1 from '../assets/fake-data/dataProject1';
import Project4 from '../components/project/Project4';
import FaqPage from '../components/faqs/FaqPage';
import Partner from '../components/partner/Partner';


function Triads(props) {
    return (
        <div className='home-3 wrapper'>
          
        <Banner4 />

        <About8 />

        <Token3 />

        <Project4 data={dataProject1} />

       {/* <Team data={dataTeam} /> */}

       <Partner/>
       <FaqPage data={dataFaqs} />




        <Counter />  

        <Blog data={dataBlog} />

        <Faq3 data={dataFaqs} />


        <Footer2 />

        </div>
    );
}

export default Triads;