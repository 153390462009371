import React from 'react';
import dataFaqs from '../assets/fake-data/data-faq';
import dataTeam from '../assets/fake-data/dataTeam';
import About from '../components/about/About';
import Banner from '../components/banner/Banner';
import Create from '../components/create/Create';
import Faqs from '../components/faqs/Faqs';
import Team from '../components/team/Team';
import Banner2 from '../components/banner/Banner2';
import dataCard from '../assets/fake-data/data-card';
import Speciality6 from '../components/speciality/Speciality6';
import dataBox6 from '../assets/fake-data/data-box-6';
import Footer2 from '../components/footer/Footer2';



function Home01(props) {

    return (
        <div className="home-1 wrapper">
            
            <Banner />

            <About />

            <Banner2 data={dataCard} />

            <Speciality6 data={dataBox6} />

            {/* <Portfolio data={dataPortfolio} /> */}

            {/* <Project data={dataProject} />
            */}
            {/* <Roadmap  data={dataRoadMap} /> */}

            {/* <Team data={dataTeam} /> */}

            {/* <Testimonials data={dataTestimonials} /> */}

            <Faqs data={dataFaqs} />

            <Create />

            <Footer2 />
        </div>
        

    );
}

export default Home01;