import React from 'react';
import About2 from '../components/about/About2';
import dataBox1 from '../assets/fake-data/data-box1';
import Speciality1 from '../components/speciality/Speciality1';
import dataBox2 from '../assets/fake-data/data-box2';
import Speciality2 from '../components/speciality/Speciality2';
import dataPortfolio2 from '../assets/fake-data/data-portfolio2';
import Portfolio2 from '../components/portfolio/Portfolio2';
import PageTitle3 from '../components/pagetitle/PageTitle3';
import Footer2 from '../components/footer/Footer2';



function Implementation(props) {
    return (
        <div className='page-about wrapper'>

            <PageTitle3 title="SAP Implementation Services" />

            <About2 />


            <Speciality1 data={dataBox1} />
            <Speciality2 data={dataBox2} />

            {/* <Counter />   */}

            <Portfolio2 data={dataPortfolio2} />

            {/* <Team data={dataTeam} /> */}

            {/* <section className="create">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">Let's Talk:</h4>
                                    <p>Ready to kickstart your business transformation <br/>
                                         journey with SAP? Fill in the form below for a free consultation, <br /> and we'll get in touch with you promptly.</p>
                                    <Button title='Contact' link='/contact' />
                                
                                </div>
                                <img src={img} alt="Trigeminal" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer2 />
            
        </div>
    );
}

export default Implementation;