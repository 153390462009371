import React from 'react';
import Speciality4 from '../components/speciality/Speciality4';
import About5 from '../components/about/About5';
import PageTitle2 from '../components/pagetitle/PageTitle2';
import dataBox4 from '../assets/fake-data/data-box-4';
import Faq4 from '../components/faqs/Faq4';
import Portfolio4 from '../components/portfolio/Portfolio4';
import dataPortfolio4 from '../assets/fake-data/data-portfolio4';
import Token1 from '../components/token/Token1';
import Footer2 from '../components/footer/Footer2';



function Consulting(props) {
    return (
        <div className='page-about wrapper'>

            <PageTitle2 title="SAP Consulting Services" />

            <About5 />


           <Speciality4 data={dataBox4}/>

           <Faq4 />
           

            {/* <Counter />   */}

            <Portfolio4 data={dataPortfolio4} />

            <Token1 />

            {/* <Team data={dataTeam} /> */}

            {/* <section className="create">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">Let's Talk:</h4>
                                    <p>Ready to kickstart your business transformation <br/>
                                         journey with SAP? Fill in the form below for a free consultation, <br /> and we'll get in touch with you promptly.</p>
                                    <Button title='Contact' link='/contact' />
                                
                                </div>
                                <img src={img} alt="Trigeminal" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer2 />
            
        </div>
    );
}

export default Consulting;