const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Home 01',
        //         links: '/'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Home 02',
        //         links: '/home-v2'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Home 03',
        //         links: '/home-v3'
        //     },

        // ]
    },
    {
        id: 2,
        name: 'SAP',
        links: '#',
        namesub: [

            {
                id: 1,
                sub: 'SAP Services',
                links: '/services-sap'

            },
            {
                id: 2,
                sub: 'SAP S/4 HANA Services',
                links: '/hana-services'
            },
            {
                id: 3,
                sub: 'SAP Implementation Services',
                links: '/implementation'
            },
            {
                id: 4,
                sub: 'SAP Upgradation Services',
                links: '/upgradation'
            },
            {
                id: 5,
                sub: 'SAP Consulting Services',
                links: '/consulting'
            },
            {
                id: 6,
                sub: 'SAP Support Services',
                links: '/support'
            },
            // {
            //     id: 7,
            //     sub: 'Participants & Assets',
            //     links: '/participants-assets'
            // },
            // {
            //     id: 7,
            //     sub: 'Advisors & Backers',
            //     links: '/advisors-backers'
            // },
            // {
            //     id: 8,
            //     sub: 'Partners & Investors',
            //     links: '/partners'
            // },
        ]
    },
    {
        id: 3,
        name: 'TriADS',
        links: '/triads',
        
    },
    {
        id: 4,
        name: 'Services',
        links: '/services',
        
    },

    {
        id: 4,
        name: 'Products',
        links: '/products',
    },
    {
        id: 5,
        name: 'About',
        links: '/about',
        
    },

    {
        id: 5,
        name: 'Blog',
        links: '/blog',
        
    },
    {
        id: 6,
        name: 'Contact',
        links: '/contact',
        
    },
  

    
]

export default menus;