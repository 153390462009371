import React , {useState} from 'react';

import Button from '../button/Button';
import Partner from '../partner/Partner';

import img from '../../assets/images/layouts/about-image.png'


function About7(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About',
            desc1: "Artificial Intelligence (AI) is reshaping every sector, with many specialized roles now enhanced by AI-driven technologies. At the heart of this transformative wave, our company is dedicated to crafting solutions that amplify efficiency in professions such as medical diagnostics, marketing, microbiology, predictive maintenance, financial forecasting, e-commerce optimization, logistics management, agricultural advancements, and urban planning. ",
            desc2: 'Our primary goal? Developing AI-powered apps designed to automate and elevate the work of industry specialists across a diverse range of fields.',
        }
    )
    return (
        <section id="vision" className="about s2">
                    <div className="shape"></div>
                    <div className="shape right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">    

                                <div className="about__main center" data-aos="fade-up" data-aos-duration="3000">
                                    <img src={img} alt="Trigeminal" />
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-30">{dataBlock.desc2}</p>
                                    
                                </div>

                                <Partner />
                            </div>
                        </div>
                    </div>
                </section>
    );
}

export default About7;