import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

PageTitle11.propTypes = {
    title: PropTypes.string.isRequired,
    
};

function PageTitle11(props) {

    
    const {title} = props
    
   

    return (
        <section className="page-title">
        <div className="shape"></div>
        <div className="shape right s3"></div>
        <div className="container">
            <div className="row">
                <div className="col-12" >
                    <div  className="page-title__body">
                        <div  className="page-title__main">
                            <h4 className="title">{title}</h4>
                            <div className="bottom"></div>

                            <ul style={{marginBottom:"100px"}} className="breacrumb">
                                
                                <li>Last updated January 01, 2024</li>
                            </ul>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default PageTitle11;