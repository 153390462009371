import React , {useState} from 'react';
import img2 from '../../assets/images/layouts/about-02.png'
import img3 from '../../assets/images/layouts/about-03.png'
import img4 from '../../assets/images/layouts/about-04.png'
import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : "https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/about-01.png",
            class: 'img1'
        },
        {
            id: 2,
            img : img2,
            class: 'img2'
        },
        {
            id: 3,
            img : img3,
            class: 'img3'
        },
        {
            id: 4,
            img : img4,
            class: 'img4'
        },
        {
            id: 5,
            img : "https://trigeminal.blr1.cdn.digitaloceanspaces.com/www/Trigeminal.ai/about-05.png",
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'Who we are',
            heading: 'Crafting Solutions with AI',
            desc1: 'Artificial intelligence (AI) is reshaping every sector, with many specialized roles now enhanced by AI-driven technologies. ' ,
            desc2 :'At the heart of this transformative wave, our company is dedicated to crafting solutions that amplify efficiency in professions such as medical diagnostics, marketing, microbiology, predictive maintenance, financial forecasting, e-commerce optimization, logistics management, agricultural advancements, and urban planning.'

        }
    )
    return (
        <section  className="about">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="Trigeminal" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-26">{dataBlock.desc2}</p>
                                    <Button link='/contact' title='Contact Us' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;