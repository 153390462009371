

const dataRoadMap1 = [

    {
        id: 1,
        title: 'Implementation',
        desc: 'Integrate skilled and seasoned SAP developers into your existing team to leverage our bespoke SAP implementation services.',
        class: '',
        links:'/implementation'

    },
    {
        id: 2,
        title: 'Upgradation',
        desc: 'Keep your business up-to-date with our top-of-the-line SAP upgrade services, ensuring you meet the ever-changing market demands. ',
        class: 'right',
        links:'/upgradation'
    },

    {
        id: 3,
        title: 'Migration',
        desc: 'Seamlessly transition to new SAP systems with our migration services, facilitated by our experienced developers. ',
        class: '',
        links:'upgradation'

    },

    {
        id: 4,
        title: 'Consulting',
        desc: 'Harness the expertise of our SAP consultants to build one-of-a-kind business solutions that empower your business to excel in the digital economy. ',
        class: 'right',
        links: '/consulting'

    },
    {
        id: 5,
        title: 'Support',
        desc: 'Benefit from ongoing support from our skilled SAP developers, ensuring your systems operate smoothly and efficiently. ',
        class: '',
        links: '/support'
        

    },

   


]

export default dataRoadMap1;