import React  from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import { Link } from 'react-router-dom';
import img2 from '../assets/images/layouts/blog-01.png'
import PageTitle5 from '../components/pagetitle/PageTitle5';
import Footer2 from '../components/footer/Footer2';



function BlogDetails(props) {

     const {data} = props;

    return (
        <div className='wrapper'>
            <PageTitle5 title='Blog Details' />

            <section className="bloglist">
                <div className="container">
                    <div className="row">
                        <div className="bloglist__main">
                            <div className="list details">
                                <h4 className="title">The Revolutionary Blog Impact of AI on Medical Diagnostics</h4>
                                <p><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                February 18, 2022</p>

                                <div className="line"></div>

                                <div className="content-d">
                                    <ul className="list-social">
                                        <li><a  href="https://www.facebook.com/sharer/sharer.php?u=https://trigeminal.ai/blog-details1" target="_blank">
                                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.57969 9.03953C2.51969 9.03953 1.19969 9.03953 0.599688 9.03953C0.279688 9.03953 0.179688 8.91953 0.179688 8.61953C0.179688 7.81953 0.179688 6.99953 0.179688 6.19953C0.179688 5.87953 0.299688 5.77953 0.599688 5.77953H2.57969C2.57969 5.71953 2.57969 4.55953 2.57969 4.01953C2.57969 3.21953 2.71969 2.45953 3.11969 1.75953C3.53969 1.03953 4.13969 0.559531 4.89969 0.279531C5.39969 0.0995311 5.89969 0.0195312 6.43969 0.0195312H8.39969C8.67969 0.0195312 8.79969 0.139531 8.79969 0.419531V2.69953C8.79969 2.97953 8.67969 3.09953 8.39969 3.09953C7.85969 3.09953 7.31969 3.09953 6.77969 3.11953C6.23969 3.11953 5.95969 3.37953 5.95969 3.93953C5.93969 4.53953 5.95969 5.11953 5.95969 5.73953H8.27969C8.59969 5.73953 8.71969 5.85953 8.71969 6.17953V8.59953C8.71969 8.91953 8.61969 9.01953 8.27969 9.01953C7.55969 9.01953 6.01969 9.01953 5.95969 9.01953V15.5395C5.95969 15.8795 5.85969 15.9995 5.49969 15.9995C4.65969 15.9995 3.83969 15.9995 2.99969 15.9995C2.69969 15.9995 2.57969 15.8795 2.57969 15.5795C2.57969 13.4795 2.57969 9.09953 2.57969 9.03953Z" fill="white"></path>
                                            </svg>    
                                        </a></li>
                                        <li><a  href="https://www.linkedin.com/sharing/share-offsite/?url=https://trigeminal.ai/blog-details1" target="_blank">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z" fill="white"></path>
                                                <path d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z" fill="white"></path>
                                                <path d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z" fill="white"></path>
                                                </svg>
                                        </a></li>
                                        {/* <li><Link to="#">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z" fill="white"></path>
                                                <path d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z" fill="white"></path>
                                                <path d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z" fill="white"></path>
                                                </svg>
                                                
                                        </Link></li> */}
                                    
                                    </ul>

                                    <div className="main">
                                        <p className="boild">The field of medicine is undergoing a transformative revolution, spearheaded by the integration of Artificial Intelligence (AI) into medical diagnostics. Gone are the days of solely relying on traditional methods for disease detection and patient prognosis. With the advent of AI technology, the landscape of medical diagnostics has been revolutionized, offering profound and far-reaching implications for healthcare professionals and patients alike.</p>
                                        <div className="image">
                                            <img src={img2} alt="Trigeminal" />
                                            <p>AI technology revolutionizes medical diagnostics, improving disease detection</p>
                                        </div>
                                        <p className="mb-15">One of the most significant contributions of AI in medical diagnostics is its role in disease detection. Traditional diagnostic methods often rely on manual interpretation of medical images, such as X-rays, MRIs, and CT scans. However, this approach is not only time-consuming but also prone to human error. AI-powered diagnostic systems, on the other hand, leverage advanced algorithms to analyze medical images with unparalleled precision and accuracy. By swiftly identifying abnormalities and anomalies in medical images, AI enables healthcare professionals to diagnose diseases at an early stage, thereby improving patient outcomes and prognosis.</p>
                                        <p className="mb-15">Moreover, AI has revolutionized the process of predicting patient outcomes. By analyzing vast amounts of patient data, including medical history, genetic information, and lifestyle factors, AI algorithms can generate predictive models to forecast disease progression and treatment outcomes. This predictive analytics capability empowers healthcare providers to tailor personalized treatment plans for individual patients, maximizing the effectiveness of medical interventions and improving overall patient care.</p>
                                        <p className="mb-15">Furthermore, AI facilitates the automation of routine administrative tasks in healthcare settings, thereby enhancing operational efficiency and reducing administrative burdens on healthcare professionals. Tasks such as patient scheduling, medical transcription, and billing can be automated using AI-powered systems, allowing healthcare providers to focus their time and expertise on delivering high-quality patient care.</p>
                                        <p className="mb-15">However, the integration of AI into medical diagnostics is not without its challenges. Privacy and data security concerns, ethical considerations, and regulatory compliance issues are some of the key challenges that need to be addressed to ensure the responsible and ethical use of AI in healthcare.</p>
                                        <p className="mb-15">In conclusion, the transformative impact of AI on medical diagnostics cannot be overstated. From disease detection to patient prognosis, AI has revolutionized the field of medicine, offering unprecedented accuracy, efficiency, and personalized care. As AI continues to evolve and advance, its role in medical diagnostics is poised to expand further, ushering in a new era of precision medicine and improved healthcare outcomes.</p>
                                       

                                        <div className="line"></div>

                                        <div className="bottom">
                                            <p>Tags:</p>
                                            <ul className="list-tag">
                                                <li><Link to="/blog">AI</Link></li>
                                                <li><Link to="/blog">Diagnostics</Link></li>
                                                <li><Link to="/blog">Technology</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="bottom">
                                    <div className="recent">
                                        <div className="ac prev">
                                            <svg width="20" height="36" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.75 16.5L1.25 9L8.75 1.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                
                                                
                                            <div>
                                                <div className="meta ">
                                                    <a href="/blog-details1"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    February 18, 2022</a>
                                                </div>
                                                <a href="/blog-details1" className="title">OpenSea NFT Marketplace to Solely Support Ethereum’s Upgraded...</a>
                                            </div>

                                        </div>
                                        <div className="ac next">
                                            <svg width="20" height="36" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.25 16.5L8.75 9L1.25 1.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                
                                                
                                                
                                            <div>
                                                <div className="meta ">
                                                    <a href="/blog-details1"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    February 18, 2022</a>
                                                </div>
                                                <a href="/blog-details1" className="title">MTV Music Awards to See Eminem, Snoop Dogg Perform in Bored...</a>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="comment">
                                        <h5>Comment (4)</h5>

                                        <div className="comment-box s1">
                                            <div className="avt">
                                                <img src={avt1} alt="Trigeminal" />
                                            </div>
                                            <div className="info">
                                                <h6 className="name">Annette Black</h6>
                                                <p className="meta">February 18, 2022</p>
                                                <p className="text">Nullam ornare a magna quis aliquet. Duis suscipit eros in suscipit venenatis. Pellentesque quis efficitur leo. Maecenas accumsan est in nibh interdum, quis dignissim neque scelerisque.</p>
                                                <Link to="#">Reply</Link>
                                            </div>
                                        </div>
                                        <div className="comment-box rep" >
                                            <div className="avt">
                                                <img src={avt2} alt="Trigeminal" />
                                            </div>
                                            <div className="info">
                                                <h6 className="name">Darlene Robertson</h6>
                                                <p className="meta">February 18, 2022</p>
                                                <p className="text">Nullam ornare a magna quis aliquet. Duis suscipit eros in suscipit venenatis. Pellentesque quis efficitur leo. Maecenas accumsan est in nibh interdum, quis dignissim neque scelerisque.</p>
                                                <Link to="#">Reply</Link>
                                            </div>
                                        </div>
                                        <div className="comment-box">
                                            <div className="avt">
                                                <img src={avt3} alt="Trigeminal" />
                                            </div>
                                            <div className="info">
                                                <h6 className="name">Ronald Richards</h6>
                                                <p className="meta">February 18, 2022</p>
                                                <p className="text">Nullam ornare a magna quis aliquet. Duis suscipit eros in suscipit venenatis. Pellentesque quis efficitur leo. Maecenas accumsan est in nibh interdum, quis dignissim neque scelerisque.</p>
                                                <Link to="#">Reply</Link>
                                            </div>
                                        </div>
                                        <div className="comment-box">
                                            <div className="avt">
                                                <img src={avt4} alt="Trigeminal" />
                                            </div>
                                            <div className="info">
                                                <h6 className="name">Theresa Webb</h6>
                                                <p className="meta">February 18, 2022</p>
                                                <p className="text">Nullam ornare a magna quis aliquet. Duis suscipit eros in suscipit venenatis. Pellentesque quis efficitur leo. Maecenas accumsan est in nibh interdum, quis dignissim neque scelerisque.</p>
                                                <Link to="#">Reply</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <form action="#" className="form-box">
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mb-14">Leave your thought here</h5>
                                                <p>Your email address will not be published. Required fields are marked *</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label >Your name</label>
                                            <input type="text" className="form-control" />
                                            </div>
                                            <div className="col">
                                                <label >Your email</label>
                                            <input type="email" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label >Your comment</label>
                                                <textarea name="comment" id="comment" cols="30" rows="10"></textarea>
                                            </div>
                
                                        </div>
                                        <div className="row mb-0">
                                            <div className="col">
                                                <button className="action-btn"><span>Submit</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div> */}
                            </div>

                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>

            <Footer2 />

        </div>
    );
}

export default BlogDetails;