
import img1 from '../images/layouts/banner-home-1.jpg'
import img2 from '../images/layouts/banner-home-4.jpg'
import img3 from '../images/layouts/banner-home-2.jpg'
import img4 from '../images/layouts/banner-home-5.jpg'
import img5 from '../images/layouts/banner-home-3.jpg'




const dataCard = [

    {
        id: 1,
        img: img1,
        title: "Exploring AI's Depths",
        price : 'Deep Dive into AI',
    },

    
    {
        id: 2,
        img: img2,
        title: 'Artificial Intelligence Explorations',
        price : 'Journeying Through AI',
    },
    {
        id: 3,
        img: img3,
        title: 'AI in Action: Real-world Application',
        price : 'Transforming Industries',
    },
    {
        id: 4,
        img: img4,
        title: 'Bringing Algorithms to Life',
        price : 'Visualizing AI Algorithms',
    },

    {
        id: 5,
        img: img5,
        title: ' Unleashing Creative Frontiers',
        price : 'AI and Creativity: From Art to Business',
    },


]

export default dataCard;