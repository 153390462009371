
import img1 from '../images/partner/DigiDxDoc.png'
import img2 from '../images/partner/Dine-O-Code.png'
import img15 from '../images/partner/Pearles Diagnostics.png'
import img4 from '../images/partner/iVET Aqua.png'
import img5 from '../images/partner/Medhelix.png'
import img6 from '../images/partner/SayGen Genetics.png'
import img7 from '../images/partner/Hoof Boss.png'
import img8 from '../images/partner/Jeevamrutham Lifestyle Spa.png'
import img9 from '../images/partner/iVET.png'
import img10 from '../images/partner/oushadhi.png'
import img11 from '../images/partner/Kingini Orchids.png'
import img13 from '../images/partner/quloom.png'
import img14 from '../images/partner/Ignite - Thrissur.png'
import img12 from '../images/partner/Petrado.png'
import img3 from '../images/partner/Dr Zoo.png'
import img16 from '../images/partner/Ubio.png'
import img17 from '../images/partner/Vetragen Healthcare.png'
import img18 from '../images/partner/Von Aanja Pet Relocation.png'
const dataPartner = [

    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4,
    },
    {
        id: 5,
        img: img5,
    },
    {
        id: 6,
        img: img6,
    },
    {
        id: 7,
        img: img7,
    },
    {
        id: 8,
        img: img8,
    },
    {
        id: 9,
        img: img9,
    },
    {
        id: 10,
        img: img10,
    },
    {
        id: 11,
        img: img11,
    },
    {
        id: 12,
        img: img12,
    },
    {
        id: 13,
        img: img13,
    },
    {
        id: 14,
        img: img14,
    },
    {
        id: 15,
        img: img15,
    },
    {
        id: 16,
        img: img16,
    },
    {
        id: 17,
        img: img17,
    },
    {
        id: 18,
        img: img18,
    },
    
   
    

]

export default dataPartner;