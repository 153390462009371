import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import data from '../../assets/fake-data/dataPartner'



Partner.propTypes = {
    
};

function Partner(props) {
    return (
        <div className='wrapper'>


            <section className="partner">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
    
                    <h6 className="sub-heading"><span>A Few of Our Clients</span></h6>

                    </div>

                    <div  className='grid-container '
                                
                            >

                                {
                                    data.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <a className='platform-logo'><img src={idx.img} alt="Trigeminal" /></a>
                                        </SwiperSlide>
                                    ))
                                }
                               
                            </div>
                            

                </div>
            </div>
        </div>
    </section>

     
            
        </div>
    );
}

export default Partner;